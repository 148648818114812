import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useParams } from 'react-router-dom'
import ReactGA from 'react-ga'
import { Auth } from 'aws-amplify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faBars } from '@fortawesome/free-solid-svg-icons'
import { changeSidebarState, getAllNotes, updateTnC } from '../../redux'
import ContactUsModal from '../Modals/contactUsModal'
import MisconductModal from '../Modals/misconductModal'
import axiosInstance from '../../utils/AxiosInstance'
import IntlMessages from '../../utils/IntlMessages'
import triangleAlertIcon from '../../assets/images/alert-triangle-icon.svg'
import notesIcon from '../../assets/images/notes-icon.svg'
import notesIconHovered from '../../assets/images/notes-icon-active.svg'
import focusIcon from '../../assets/images/focus_icon.png'
import focusIconWhite from '../../assets/images/focus_icon_white.png'
import avator from '../../assets/images/profile-image.png'
import { faHeart as heart } from '@fortawesome/free-regular-svg-icons'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import socket from '../../utils/notificationSocket'
import Notifications from './notifications'
import TermAndCondition from './TermAndCondition'
import useOnClickOutside from 'use-onclickoutside'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom'
import PeerSharingModal from '../Modals/PeerSharingModal'
import mySparkBlack from '../../assets/icons/Asset 1.svg'
import mySparkWhite from '../../assets/icons/Group 3819.svg'
import HSmySpark from '../../assets/images/LTS-HS/Spark .svg'
import HSGooglePlay from '../../assets/images/LTS-HS/Story in motion-01.svg'
import HSCommunity from '../../assets/images/LTS-HS/Community-01.svg'
import { getUserStory } from '../../redux/portfolio/Actions'

const NavbarIcon = (props) => {
  return (
    <li className='nav-item  my-auto'>
      <NavLink
        className={`nav-link m-0 p-0 icon-menu ${props.cn}`}
        to={props.to}
      >
        <img
          src={props.srcWithFocus}
          width={props.width}
          height={props.height}
          style={props.style}
          alt={props.alt}
        />
      </NavLink>
    </li>
  )
}

function Header(props) {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.user.user)
  const userStory = useSelector((state) => state.portfolio.whoSection.userStory)
  const mainState = useSelector((state) => state)
  const currentLanguage = useSelector((state) => state.lang.locale)
  const sideBarState = useSelector((state) => state.general.sidebarState)
  const notes = useSelector((state) => state.course)
  const history = useHistory()
  const location = useLocation()
  const [firstNote, setFirstNote] = useState('')
  // eslint-disable-next-line
  const [mediaHovered, setMediaHovered] = useState(false)
  const [verifiedEmail, setVerifiedEmail] = useState(null)
  const [currentUser, setCurrentUser] = useState({})
  const [userMessage, setUserMessage] = useState('')
  const [showContactModal, setShowContactModal] = useState(false)
  const [showMisconductReportModal, setShowMisconductReportModal] =
    useState(false)
  const [showMobileDropDown, setShowMobileDropDown] = useState(false)
  const [showDropDown, setShowDropDown] = useState(false)
  const name = useSelector((state) => state.user.user.user.name)
  const [notifications, setNotifications] = useState([])
  const [unreadNotifications, setUnreadNotifications] = useState(0)
  const [showNotifications, setShowNotifications] = useState(false)
  const [TnCModal, setOpenTnCModal] = useState(false)
  const [peerSharingModal, setPeerSharingModal] = useState(false)
  const [peerSharingAccepted, setPeerSharingAccepted] = useState(false)
  const backButton = useSelector((state) => state.backButton)

  useEffect(() => {
    axiosInstance.get('/peerSharing/').then(({ data }) => {
      if (data) {
        setPeerSharingAccepted(data.peerSharingAccepted)
      }
    })
  }, [])
  const acceptPeerSharing = (value) => {
    axiosInstance
      .post('/peerSharing/', { peerSharingAccepted: value })
      .then(({ data }) => {
        if (data) {
          setPeerSharingAccepted(data.peerSharingAccepted)
          history.push('/my-classroom')
          closePeerSharingModal()
        }
      })
  }

  const openPeerSharingModal = () => {
    setPeerSharingModal(true)
  }
  const closePeerSharingModal = () => {
    setPeerSharingModal(false)
  }
  /**
   * we are using these temporary vars
   * since state is not updating immediately
   */

  useEffect(() => {
    isUserAgredToTnC()
    ReactGA.initialize('UA-130670492-3')
    ReactGA.pageview(window.location.href)
  })

  const notificationsRef = useRef(null)
  useOnClickOutside(notificationsRef, () => {
    setTimeout(() => {
      setShowNotifications(false)
    }, 100)
  })

  useEffect(() => {
    if (user) {
      socket?.emit('newNotificationUser', {
        name: user.name,
        id: user.id
      })

      socket?.on('getNotification', (data) => {
        setNotifications((notifications) => [
          {
            ...data.notification,
            Sender: { ...data.Sender }
          },
          ...notifications
        ])
        setUnreadNotifications(
          (unreadNotifications) => Number(unreadNotifications) + 1
        )
      })

      return () => {
        socket.disconnect()
      }
    }
  }, [user?.id, user?.name])

  useEffect(() => {
    checkIfUserHasVerifiedEmail()

    axiosInstance.get(`/notifications/${user.id}`).then((res) => {
      if (res.data.notifications.length > 0) {
        setNotifications(res.data.notifications)
      }

      setUnreadNotifications(res.data.unreadNotifications)
    })
  }, [user.id])

  useEffect(
    function () {
      dispatch(
        getAllNotes({
          userId: user.sub
        })
      )
    },

    [dispatch, user.sub]
  )

  useEffect(() => {
    if (notes.notes?.length) {
      notes.notes.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
      setFirstNote(notes.notes[0].id)
    }
  }, [notes.notes])

  const closeDropDownMenu = () => {
    if (showDropDown) {
      setTimeout(() => {
        setShowDropDown(false)
      }, 200)
    }
  }

  const checkIfUserHasVerifiedEmail = async () => {
    await Auth.currentAuthenticatedUser({ bypassCache: true })
      .then((res) => {
        setCurrentUser(res)
        if (res.attributes['custom:isVerified'] === '1') {
          setVerifiedEmail(true)
        } else {
          setVerifiedEmail(false)
        }
      })
      .catch((err) => err)
  }

  const sendVerifyEmail = async () => {
    let params = {
      email: currentUser.attributes.email,
      universityCode: currentUser.attributes['custom:universityCode'],
      language: currentLanguage
    }
    setUserMessage('user.sending_verification_email')
    await axiosInstance
      .post(
        `${process.env.REACT_APP_SERVER_BASE_URL}users/verify-email`,
        params
      )
      .then(() => {
        setUserMessage('user.verification_email_sent')
      })
  }

  const showModal = () => {
    setShowContactModal(true)
  }

  const showMisconductModal = () => {
    setShowMisconductReportModal(true)
  }

  const closeModal = () => {
    setShowContactModal(false)
  }

  const isUserAgredToTnC = () => {
    var pathArray = window.location.pathname.split('/')[1]
    if (!user.TnC && pathArray != 'terms') {
      setOpenTnCModal(true)
    }
  }

  const handleMobileNavBar = () => {
    if (showMobileDropDown == true) {
      setShowMobileDropDown(false)
    }
    dispatch(changeSidebarState(!sideBarState))
  }

  useEffect(() => {
    dispatch(getUserStory())
  }, [])
  return (
    <div>
      {window.location.href.includes('demo') ? null : verifiedEmail === false &&
        (window.location.href.includes('dashboard') ||
          window.location.href ===
            `${process.env.REACT_APP_CLIENT_BASE_URL}/account`) ? (
        <div className='verify-email'>
          {userMessage !== '' ? (
            <p>
              <IntlMessages id={`${userMessage}`} />
            </p>
          ) : (
            <p>
              <img className='mr-2' src={triangleAlertIcon} alt='triangle' />
              <IntlMessages id='user.verify_email' />
              <Link to='#' className='link' onClick={() => sendVerifyEmail()}>
                <IntlMessages id='user.click_to_verify_email' />
              </Link>
              <IntlMessages id='user.resend_verification_email' />
            </p>
          )}
        </div>
      ) : null}
      <nav className='navbar navbar-expand-lg navbar-light bg-light desktop-menu px-xl-2'>
        <div className='container-fluid'>
          <button
            type='button'
            id='sidebarCollapse'
            className='btn'
            style={{
              backgroundColor: '#01c5d1'
            }}
            onClick={() => {
              handleMobileNavBar()
            }}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul className='navbar-nav  mt-1'>
              {backButton.state && (
                <div style={{ display: 'inherit' }}>
                  <li className='nav-item my-auto'>
                    <button
                      className={`nav-link icon-menu px-2 me-2 my-auto back_button `}
                      onClick={() => history.push('/' + backButton.location)}
                      style={{ border: 'none' }}
                    >
                      <FontAwesomeIcon
                        icon={faAngleLeft}
                        style={{
                          fontSize: '26px'
                        }}
                        className='pt-1'
                      />
                    </button>
                  </li>
                </div>
              )}
            </ul>
            {user.level === 'HS' ? (
              <ul className='navbar-nav ms-auto mt-1'>
                <NavbarIcon
                  to={'/story-in-motion'}
                  cn={'hs-icon'}
                  srcWithFocus={HSGooglePlay}
                />
                <NavbarIcon
                  to={'/my-spark/widgets'}
                  cn={'spark-icon'}
                  srcWithFocus={HSmySpark}
                />
                <NavbarIcon
                  to={'/my-classroom'}
                  cn={'comm-icon my-auto'}
                  srcWithFocus={HSCommunity}
                  width={'55px'}
                  height={'45px'}
                />
                <div
                  // onClick={() => setShowNotifications(false)}
                  style={{ display: 'inherit' }}
                >
                  <div
                    className='my-auto mx-3'
                    style={{ borderRight: '1px solid #BBBDBF', height: '20px' }}
                  ></div>
                  <li className='nav-item my-auto me-2 position-relative'>
                    <a
                      className={`nav-link icon-menu px-2 my-auto nav-notifications position-relative ${
                        showNotifications ? 'active' : ''
                      }`}
                      onClick={() => setShowNotifications((state) => !state)}
                      href
                    >
                      <FontAwesomeIcon
                        icon={faBell}
                        style={{
                          fontSize: '30px',
                          color: '#333D3D'
                        }}
                        className='nav-bell-icon pt-1'
                      />
                      {unreadNotifications > 0 && (
                        <span className='badge nofitication-badge'>
                          {unreadNotifications}
                        </span>
                      )}
                    </a>
                    {showNotifications && (
                      <Notifications
                        unreadNotifications={unreadNotifications}
                        notifications={notifications}
                        setShowNotifications={setShowNotifications}
                        setUnreadNotifications={setUnreadNotifications}
                        notificationsRef={notificationsRef}
                      />
                    )}
                  </li>
                  <li className='nav-item my-auto me-2'>
                    <NavLink
                      onMouseOver={() => setMediaHovered(true)}
                      onMouseLeave={() => setMediaHovered(false)}
                      className={`nav-link icon-menu px-2 my-auto `}
                      to={'/savedMedia'}
                    >
                      <FontAwesomeIcon
                        icon={heart}
                        style={{ fontSize: '30px' }}
                        className='pt-1'
                      />
                    </NavLink>
                  </li>
                  <li className='nav-item notes-nav my-auto me-5'>
                    <NavLink
                      className={`nav-link icon-menu`}
                      to={
                        firstNote !== ''
                          ? `${`/my-notes/${firstNote}`}`
                          : '/my-notes'
                      }
                    >
                      <div>
                        <img
                          src={notesIconHovered}
                          className='d-none focus-icon'
                          width='27px'
                          alt='note'
                        />
                        <img
                          src={notesIcon}
                          className='not-focus-icon'
                          width='27px'
                          alt='note'
                        />
                      </div>
                    </NavLink>
                  </li>
                  <li className='nav-item dropdown ms-2'>
                    <div
                      className='dropdown-li'
                      tabIndex='0'
                      onBlur={() => closeDropDownMenu()}
                    >
                      <button
                        className='btn btn-secondary dropdown-toggle menu-dropdown'
                        type='button'
                        id='dropdownMenuButton'
                        data-toggle='dropdown'
                        aria-haspopup='true'
                        aria-expanded='false'
                        onClick={() => setShowDropDown((preState) => !preState)}
                      >
                        <div className='profile-dropdown me-1 ms-3 desktop-menu d-none d-xl-block'>
                          <img
                            // src={
                            //   mainState.user.user.user.profileImage
                            //     ? mainState.user.user.user.profileImage
                            //     : avator
                            // }

                            src={
                              userStory?.data?.userImageUrl
                                ? userStory?.data?.userImageUrl
                                : avator
                            }
                            alt='Profile'
                          />
                        </div>
                        <div className='profile-dropdown-info desktop-menu'>
                          <h5>{name ? name : localStorage.getItem('name')}</h5>
                          <p>{user.email}</p>
                        </div>
                      </button>
                      <div
                        className={`dropdown-menu${
                          showDropDown ? 'show1' : ''
                        } p-0 text-uppercase`}
                        aria-labelledby='dropdownMenuButton'
                      >
                        <Link
                          className='dropdown-item py-2 dropdown-menu-hover'
                          to='/account'
                          onClick={() =>
                            setShowDropDown((preState) => !preState)
                          }
                        >
                          <IntlMessages id='my_account.page_title' />
                        </Link>
                        {
                          <Link
                            className='dropdown-item py-2 dropdown-menu-hover'
                            to='/archived-portfolio'
                            onClick={() =>
                              setShowDropDown((preState) => !preState)
                            }
                          >
                            MY ARCHIVED PORTFOLIO
                          </Link>
                        }
                        <Link
                          className='dropdown-item py-2 dropdown-menu-hover'
                          to='#'
                          onClick={showModal}
                        >
                          <IntlMessages id='navigation.contact_us' />
                        </Link>
                        <Link
                          className='dropdown-item py-2 dropdown-menu-hover'
                          to='#'
                          onClick={showMisconductModal}
                        >
                          REPORT MISCONDUCT
                        </Link>
                        <Link
                          className='dropdown-item py-2 dropdown-menu-hover'
                          to='/logout'
                        >
                          <IntlMessages id='navigation.logout' />
                        </Link>
                      </div>
                    </div>
                  </li>
                </div>
              </ul>
            ) : (
              <ul className='navbar-nav ms-auto mt-1'>
                <li className='nav-item my-auto me-2 position-relative'>
                  <a
                    className={`nav-link icon-menu px-2 my-auto nav-notifications position-relative ${
                      showNotifications ? 'active' : ''
                    }`}
                    onClick={() =>
                      !showNotifications && setShowNotifications(true)
                    }
                  >
                    <FontAwesomeIcon
                      icon={faBell}
                      style={{
                        fontSize: '26px',
                        color: '#333D3D'
                      }}
                      className='nav-bell-icon pt-1'
                    />
                    {unreadNotifications > 0 && (
                      <span className='badge nofitication-badge'>
                        {unreadNotifications}
                      </span>
                    )}
                  </a>
                  {showNotifications && (
                    <Notifications
                      unreadNotifications={unreadNotifications}
                      notifications={notifications}
                      setShowNotifications={setShowNotifications}
                      setUnreadNotifications={setUnreadNotifications}
                      notificationsRef={notificationsRef}
                    />
                  )}
                </li>
                <div
                  onClick={() => setShowNotifications(false)}
                  style={{ display: 'inherit' }}
                >
                  <li className='nav-item my-auto'>
                    {/*<NavLink*/}
                    {/*  className={`nav-link icon-menu px-2 me-2 my-auto`}*/}
                    {/*  to={'/my-connections'}*/}
                    {/*>*/}
                    <NavLink
                      className={`nav-link icon-menu px-2 me-2 my-auto`}
                      to={
                        peerSharingAccepted
                          ? '/my-classroom'
                          : location.pathname
                      }
                      onClick={() => {
                        if (!peerSharingAccepted) {
                          openPeerSharingModal()
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faUsers}
                        style={{
                          fontSize: '26px'
                        }}
                        className='pt-1'
                      />
                    </NavLink>
                  </li>
                  <li className='nav-item spotlight-nav my-auto'>
                    <NavLink
                      className={`nav-link icon-menu px-2 my-auto`}
                      to={'/spotlight'}
                    >
                      <div>
                        <img
                          src={focusIconWhite}
                          className='d-none focus-icon'
                          width='28px'
                          alt='note'
                        />
                        <img
                          src={focusIcon}
                          className='not-focus-icon'
                          width='28px'
                          alt='note'
                        />
                      </div>
                    </NavLink>
                  </li>
                  <div
                    className='my-auto mx-3'
                    style={{ borderRight: '1px solid #BBBDBF', height: '20px' }}
                  ></div>
                  <li className='nav-item my-auto me-2'>
                    <NavLink
                      onMouseOver={() => setMediaHovered(true)}
                      onMouseLeave={() => setMediaHovered(false)}
                      className={`nav-link icon-menu px-2 my-auto `}
                      to={'/savedMedia'}
                    >
                      <FontAwesomeIcon
                        icon={heart}
                        style={{ fontSize: '26px' }}
                        className='pt-1'
                      />
                    </NavLink>
                  </li>
                  <li className='nav-item notes-nav my-auto me-2'>
                    <NavLink
                      className={`nav-link icon-menu`}
                      to={
                        firstNote !== ''
                          ? `${`/my-notes/${firstNote}`}`
                          : '/my-notes'
                      }
                    >
                      <div>
                        <img
                          src={notesIconHovered}
                          className='d-none focus-icon'
                          width='25px'
                          alt='note'
                        />
                        <img
                          src={notesIcon}
                          className='not-focus-icon'
                          width='25px'
                          alt='note'
                        />
                      </div>
                    </NavLink>
                  </li>
                  <li className='nav-item notes-nav my-auto me-5'>
                    <NavLink
                      className={`nav-link px-2 me-1 icon-menu`}
                      to={'/my-spark/widgets'}
                    >
                      <div>
                        <img
                          src={mySparkWhite}
                          className='d-none focus-icon'
                          width='21px'
                          alt='note'
                        />
                        <img
                          src={mySparkBlack}
                          className='not-focus-icon'
                          width='21px'
                          alt='note'
                        />
                      </div>
                    </NavLink>
                  </li>
                  <li className='nav-item dropdown ms-2'>
                    <div
                      className='dropdown-li'
                      tabIndex='0'
                      onBlur={() => closeDropDownMenu()}
                    >
                      <button
                        className='btn btn-secondary dropdown-toggle menu-dropdown'
                        type='button'
                        id='dropdownMenuButton'
                        data-toggle='dropdown'
                        aria-haspopup='true'
                        aria-expanded='false'
                        onClick={() => setShowDropDown((preState) => !preState)}
                      >
                        <div className='profile-dropdown me-1 ms-3 desktop-menu d-none d-xl-block'>
                          <img
                            // src={
                            //   mainState.user.user.user.profileImage
                            //     ? mainState.user.user.user.profileImage
                            //     : avator
                            // }
                            src={
                              userStory?.data?.userImageUrl
                                ? userStory?.data?.userImageUrl
                                : avator
                            }
                            alt='Profile Image'
                          />
                        </div>
                        <div className='profile-dropdown-info desktop-menu'>
                          <h5>{name ? name : localStorage.getItem('name')}</h5>
                          <p>{user.email}</p>
                        </div>
                      </button>
                      <div
                        className={`dropdown-menu${
                          showDropDown ? 'show1' : ''
                        } p-0 text-uppercase`}
                        aria-labelledby='dropdownMenuButton'
                      >
                        <Link
                          className='dropdown-item py-2 dropdown-menu-hover'
                          to='/account'
                          onClick={() =>
                            setShowDropDown((preState) => !preState)
                          }
                        >
                          <IntlMessages id='my_account.page_title' />
                        </Link>
                        {
                          <Link
                            className='dropdown-item py-2 dropdown-menu-hover'
                            to='/archived-portfolio'
                            onClick={() =>
                              setShowDropDown((preState) => !preState)
                            }
                          >
                            MY ARCHIVED PORTFOLIO
                          </Link>
                        }
                        <Link
                          className='dropdown-item py-2 dropdown-menu-hover'
                          to='#'
                          onClick={showModal}
                        >
                          <IntlMessages id='navigation.contact_us' />
                        </Link>
                        <Link
                          className='dropdown-item py-2 dropdown-menu-hover'
                          to='#'
                          onClick={showMisconductModal}
                        >
                          REPORT MISCONDUCT
                        </Link>
                        <Link
                          className='dropdown-item py-2 dropdown-menu-hover'
                          to='/logout'
                        >
                          <IntlMessages id='navigation.logout' />
                        </Link>
                      </div>
                    </div>
                  </li>
                </div>
              </ul>
            )}
          </div>
        </div>
      </nav>
      <nav className='navbar navbar-expand-lg navbar-light bg-light mobile-menu'>
        <div className='container-fluid'>
          <button
            type='button'
            id='sidebarCollapse'
            className='btn'
            style={{
              backgroundColor: '#01c5d1'
            }}
            onClick={() => {
              dispatch(changeSidebarState(!sideBarState))
              setShowDropDown(false)
            }}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
          <div className='col-2 d-flex ms-auto flex-row-reverse'>
            <button
              className='btn d-inline-block'
              type='button'
              id='dropdownMenuButton'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
              onClick={() => {
                setShowDropDown((preState) => !preState)
              }}
            >
              <FontAwesomeIcon icon={faBars} />
            </button>
            <ul className='navbar-nav my-auto'>
              <li className='nav-item my-auto position-relative nav-notifications-li'>
                <a
                  className={`nav-link icon-menu px-2 my-auto nav-notifications position-relative ${
                    showNotifications ? 'active' : ''
                  }`}
                  onClick={() => setShowNotifications(!showNotifications)}
                >
                  <FontAwesomeIcon
                    icon={faBell}
                    style={{
                      fontSize: '26px',
                      color: '#333D3D'
                    }}
                    className='nav-bell-icon pt-1'
                  />
                  {unreadNotifications > 0 && (
                    <span className='badge nofitication-badge'>
                      {unreadNotifications}
                    </span>
                  )}
                </a>
                {showNotifications && (
                  <Notifications
                    unreadNotifications={unreadNotifications}
                    notifications={notifications}
                    setShowNotifications={setShowNotifications}
                    setUnreadNotifications={setUnreadNotifications}
                  />
                )}
              </li>
              <div
                // onClick={() => setShowNotifications(false)}
                style={{ display: 'inherit' }}
              >
                <li className='nav-item spotlight-nav my-auto'>
                  <NavLink
                    className={`nav-link icon-menu px-2 me-2 my-auto`}
                    to={'/spotlight'}
                  >
                    <div>
                      <img
                        src={focusIconWhite}
                        className='d-none focus-icon'
                        width='28px'
                        alt='note'
                      />
                      <img
                        src={focusIcon}
                        className='not-focus-icon'
                        width='28px'
                        alt='note'
                      />
                    </div>
                  </NavLink>
                </li>
                <li className='nav-item my-auto'>
                  <NavLink
                    className={`nav-link icon-menu px-2 me-2 my-auto`}
                    to={'/savedMedia'}
                  >
                    <FontAwesomeIcon
                      icon={heart}
                      style={{ fontSize: '26px' }}
                      className='pt-1'
                    />
                  </NavLink>
                </li>
                <li className='nav-item notes-nav my-auto'>
                  <NavLink
                    className={`nav-link px-2 me-1 icon-menu`}
                    to={
                      firstNote !== ''
                        ? `${`/my-notes/${firstNote}`}`
                        : '/my-notes'
                    }
                  >
                    <div>
                      <img
                        src={notesIconHovered}
                        className='d-none focus-icon'
                        width='25px'
                        alt='note'
                      />
                      <img
                        src={notesIcon}
                        className='not-focus-icon'
                        width='25px'
                        alt='note'
                      />
                    </div>
                  </NavLink>
                </li>
                <li className='nav-item notes-nav my-auto '>
                  <NavLink
                    className={`nav-link px-2 me-1 icon-menu`}
                    to={'/my-spark/widgets'}
                  >
                    <div>
                      <img
                        src={mySparkWhite}
                        className='d-none focus-icon'
                        width='21px'
                        alt='note'
                      />
                      <img
                        src={mySparkBlack}
                        className='not-focus-icon'
                        width='21px'
                        alt='note'
                      />
                    </div>
                  </NavLink>
                </li>
              </div>
            </ul>
          </div>
        </div>
        <div className='dropdown-li'>
          <button
            className='btn btn-secondary  menu-dropdown'
            type='button'
            id='dropdownMenuButton'
            data-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
            onClick={() => setShowDropDown((preState) => !preState)}
          >
            <div className='profile-dropdown me-1 ms-3 desktop-menu'>
              <img
                // src={
                //   mainState.user.user.user.profileImage
                //     ? mainState.user.user.user.profileImage
                //     : avator
                // }
                src={
                  userStory?.data?.userImageUrl
                    ? userStory?.data?.userImageUrl
                    : avator
                }
                alt='Profile'
              />
            </div>
            <div className='profile-dropdown-info desktop-menu'>
              <h5>{name}</h5>
              <p>{user.email}</p>
            </div>
          </button>
          <div
            className={`dropdown-menu${
              showDropDown ? 'show1' : ''
            } p-0 text-uppercase`}
            aria-labelledby='dropdownMenuButton'
          >
            <Link
              className='dropdown-item py-2 dropdown-menu-hover'
              to='/account'
              onClick={() => setShowDropDown((preState) => !preState)}
            >
              <IntlMessages id='my_account.page_title' />
            </Link>
            {
              <Link
                className='dropdown-item py-2 dropdown-menu-hover'
                to='/archived-portfolio'
                onClick={() => setShowDropDown((preState) => !preState)}
              >
                MY ARCHIVED PORTFOLIO
              </Link>
            }
            {/* <Link
              className='dropdown-item py-2 dropdown-menu-hover'
              to='/MyStartupProfile'
              onClick={() => setShowDropDown((preState) => !preState)}
            >
              MY PROJECTS
            </Link> */}
            <Link
              className='dropdown-item py-2 dropdown-menu-hover'
              to='/my-connections'
              onClick={() => setShowDropDown((preState) => !preState)}
            >
              MY COMMUNITY
            </Link>
            <Link
              className='dropdown-item py-2 dropdown-menu-hover'
              to='#'
              onClick={showModal}
            >
              <IntlMessages id='navigation.contact_us' />
            </Link>
            <Link
              className='dropdown-item py-2 dropdown-menu-hover'
              to='#'
              onClick={showMisconductModal}
            >
              REPORT MISCONDUCT
            </Link>
            <Link
              className='dropdown-item py-2 dropdown-menu-hover'
              to='/logout'
            >
              <IntlMessages id='navigation.logout' />
            </Link>
          </div>
        </div>
      </nav>

      <ContactUsModal
        show={showContactModal}
        onHide={closeModal}
        user={currentUser}
      />

      <MisconductModal
        show={showMisconductReportModal}
        onHide={() => setShowMisconductReportModal(false)}
      />
      <TermAndCondition
        show={TnCModal}
        onHide={() => {
          user.TnC = true
          setOpenTnCModal(false)
          dispatch(updateTnC())
        }}
      />
      <PeerSharingModal
        show={peerSharingModal}
        onHide={closePeerSharingModal}
        peerSharingAccepted={peerSharingAccepted}
        handleChange={(value) => acceptPeerSharing(value)}
      />
    </div>
  )
}
export default Header
