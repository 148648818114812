/* Top Level Route file */

import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useSelector, connect } from 'react-redux'
import { IntlProvider } from 'react-intl'
import AppLocale from './lang'
import Layout from './pages/Layout'
import PublicLayout from './pages/Layout/publicLayout'
import CSVUpload from './components/CSVUpload'
import VerifyEmailByCode from './pages/Register/verifyEmailByCode'
import LtsJournal from './pages/LtsJournal'
import MyCourseEntrepreneurship from './pages/MyCourseEntrepreneurship'
import AddOccupationItemForm from './components/Pathways/CreateOccupation/index.js'

const Login = React.lazy(() => import('./pages/Auth/Login'))
const HSLogin = React.lazy(() => import('./pages/Auth/Login/HS-index'))
const HSChooseLogin = React.lazy(() =>
  import('./pages/Auth/Login/ChooseLogin/HSChooseLogin')
)
const ChooseLogin = React.lazy(() =>
  import('./pages/Auth/Login/ChooseLogin/ChooseLogin')
)
const SecurePage = React.lazy(() => import('../src/pages/Secure'))
const ForgotPassword = React.lazy(() =>
  import('./pages/Auth/Login/forgotPassword')
)
const ResetPassword = React.lazy(() =>
  import('./pages/Auth/Login/resetPassword')
)
const CreateAccount = React.lazy(() =>
  import('./pages/Auth/Login/createAccount')
)
const NotFound = React.lazy(() => import('../src/pages/NotFound'))

const Terms = React.lazy(() => import('./pages/Terms'))
const Register = React.lazy(() => import('./pages/Register'))
const Dashboard = React.lazy(() => import('./pages/Dashboard'))
const Portfolio = React.lazy(() => import('./pages/Portfolio'))
const SavedMedia = React.lazy(() => import('./pages/Saved'))
const Resubscribe = React.lazy(() => import('./pages/Resubscribe'))
const StartupLive = React.lazy(() => import('./pages/StartupLive'))
const Spotlight = React.lazy(() => import('./pages/Spotlight'))
const LiveStream = React.lazy(() => import('./pages/StartupLive/livestream'))
const AllVideos = React.lazy(() => import('./pages/BeyondYourCourse/allVideos'))
const BeyondYourCourse = React.lazy(() => import('./pages/BeyondYourCourse'))
const BeyondYourCourseVideo = React.lazy(() =>
  import('../src/pages/BeyondYourCourse/beyondYourCourseVideo')
)
const Profile = React.lazy(() => import('./pages/Profile/index'))
const ProfilePreview = React.lazy(() =>
  import('./pages/Profile/profilePreview')
)
const MyMarketReadyGuide = React.lazy(() =>
  import('./pages/MyMarketReadyGuide')
)

const IAMR = React.lazy(() => import('../src/pages/Iamr'))

const PreviewPublicPortfolio = React.lazy(() =>
  import('./pages/PortfolioNew/previewPublicPortfolio')
)

const UserPortfolioProj = React.lazy(() =>
  import('./pages/StartupProfile/userProjects')
)
const MySpark = React.lazy(() => import('./pages/MySpark'))
const MySparkWidgetDetails = React.lazy(() =>
  import('./pages/MySpark/Widgets/WidgetDetails')
)
const MySparkGeneratePage = React.lazy(() =>
  import('./pages/MySpark/GeneratedResponsePage/GeneratedResponsePage')
)
const MySparkArchivePage = React.lazy(() =>
  import('./pages/MySpark/ArchivePage/ArchivePage')
)
const MyNotes = React.lazy(() => import('../src/pages/MyNotes'))
const SampleNote = React.lazy(() => import('../src/pages/MyNotes/sampleNote'))
const MyJournals = React.lazy(() => import('../src/pages/MyJournals'))
const Logout = React.lazy(() => import('./pages/Auth/LogOut'))
const VerifyEmail = React.lazy(() => import('./pages/Verify'))
const MyClassroom = React.lazy(() => import('./pages/MyClassroom'))
const StoryInMotion = React.lazy(() => import('./pages/StoryInMotion'))
const PreviewPortfolioNew = React.lazy(() =>
  import('./pages/PortfolioNew/previewPortfolio')
)
const EditPortfolioNew = React.lazy(() =>
  import('./pages/PortfolioNew/editPortfolio')
)

const PasswordChangeRequired = React.lazy(() =>
  import('./pages/Auth/Login/passwordChangeRequired')
)
const MyImmersion = React.lazy(() => import('./pages/MyImmersion'))
const Steps = React.lazy(() => import('./pages/MyImmersion/Steps'))

const Pathways = React.lazy(() => import('./pages/Pathways'))

function Router(props) {
  const currentAppLocale = AppLocale[props.locale]
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated)
  const isStudent = useSelector((state) => state.user.user?.user?.role_id) === 1
  const isHighSchoolStudent =
    useSelector((state) => state.user?.user?.user?.level) === 'HS'

  const studentLevel = useSelector((state) => state.user.user?.user?.level)
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
      onError={() => ''}
    >
      <React.Fragment>
        {isAuthenticated ? (
          <Layout>
            <Switch>
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/savedMedia" component={SavedMedia} />
              <Route path="/csv-upload" component={CSVUpload} />
              <Route path="/portfolio" component={Portfolio} />

              <Route
                exact
                path="/edit-portfolio"
                component={EditPortfolioNew}
                // component={HighSchoolPortfolio}
              />
              <Route
                exact
                path="/edit-portfolio/recommendation/:id"
                component={EditPortfolioNew}
              />
              <Route
                path="/preview-portfolio"
                component={PreviewPortfolioNew}
              />
              <Route
                exact
                path="/user-portfolio/:username"
                component={(props) => {
                  return studentLevel !== 'HS' ? (
                    <PreviewPortfolioNew {...props} isPublicView={false} />
                  ) : (
                    <></>
                  )
                }}
              />
              <Route
                exact
                path="/beyond-your-course"
                component={BeyondYourCourse}
              />

              <Route
                exact
                path="/beyond-your-course/:id"
                component={BeyondYourCourse}
              />
              <Route path="/story-in-motion" component={StoryInMotion} />
              <Route path="/UserProject/:uid" component={UserPortfolioProj} />
              <Route path="/:page/videos" component={AllVideos} />
              <Route exact path="/startup-live" component={StartupLive} />
              <Route exact path="/spotlight" component={Spotlight} />
              <Route exact path="/startup-livestream" component={LiveStream} />
              <Route exact path="/account" component={Profile} />
              <Route exact path="/profile-preview" component={ProfilePreview} />
              <Route
                path="/my-mentorship/"
                component={(props) => (
                  <LtsJournal {...props} category="my-mentorship" />
                )}
              />
              <Route
                path="/lts-journal/"
                component={(props) => (
                  <LtsJournal {...props} category="student-lts" />
                )}
              />
              <Route
                path="/wellness-journal/"
                component={(props) => (
                  <LtsJournal {...props} category="student-wellnes" />
                )}
              />
              <Route
                path="/student-personal-finance/"
                component={(props) => (
                  <LtsJournal {...props} category="student-personal-finance" />
                )}
              />
              <Route
                path="/my-mentorship/"
                component={(props) => (
                  <LtsJournal {...props} category="my-mentorship" />
                )}
              />
              <Route
                path="/my-leadership-journal/"
                component={(props) => (
                  <LtsJournal {...props} category="student-leadership" />
                )}
              />
              <Route
                path="/market-ready-journal/"
                component={(props) => (
                  <LtsJournal {...props} category="market-ready" />
                )}
              />
              <Route
                exact
                path="/my-course-in-entrepreneurship"
                component={MyCourseEntrepreneurship}
              />
              <Route
                path="/my-course-in-entrepreneurship/journal"
                component={(props) => (
                  <LtsJournal {...props} category="entrepreneurship" />
                )}
              />
              <Route
                exact
                path="/My-Market-Ready-Guide"
                component={MyMarketReadyGuide}
              />
              <Route
                path="/:page/video/:id"
                component={BeyondYourCourseVideo}
              />
              <Route path="/sample-note" component={SampleNote} />
              <Route exact path="/my-notes/:id?" component={MyNotes} />
              <Route
                exact
                path="/my-journal/:month/:id"
                component={MyJournals}
              />
              <Route exact path="/my-account" component={Profile} />
              <Route path="/verify" component={VerifyEmail} />
              <Route path="/logout" component={Logout} />
              {isStudent && <Route exact path="/iamr" component={IAMR} />}
              {isStudent && (
                <Route
                  path="/iamr/:certificationType?/:id?/:type?"
                  component={IAMR}
                />
              )}
              <Route exact path="/my-classroom" component={MyClassroom} />
              <Route
                path="/my-spark/generate-page/response"
                exact
                component={MySparkGeneratePage}
              />
              <Route
                path="/my-spark/generate-page/:id"
                exact
                component={MySparkGeneratePage}
              />
              <Route exact path="/my-spark/widgets" component={MySpark} />
              <Route
                path="/my-spark/archive"
                exact
                component={MySparkArchivePage}
              />
              <Route
                path="/my-spark/widgets/:widgetName"
                exact
                component={MySparkWidgetDetails}
              />
              {isHighSchoolStudent && (
                <Route exact path="/my-immersion" component={MyImmersion} />
              )}
              {isHighSchoolStudent && (
                <Route path="/my-immersion/:step" component={Steps} />
              )}
              {/*<Route*/}
              {/*  exact*/}
              {/*  path="/my-connections/request/:id"*/}
              {/*  component={MyClassroom}*/}
              {/*/>*/}
              <Route exact path="/terms" component={Terms} />
              <Route
                exact
                path="/pathways"
                component={(props) => <Pathways {...props} />}
              />
              <Route
                // exact
                path="/pathways/:occupationId?/:occupationJobId?"
                component={(props) => <Pathways {...props} />}
              />
              <Route
                path="/create-occupation/:occupationGroupId"
                component={AddOccupationItemForm}
              />

              <Route exact path="/old-portfolio" component={EditPortfolioNew} />
              <Redirect from="/register" exact to="/dashboard" />

              <Redirect from="/main-login" exact to="/dashboard" />
              <Redirect from="/" exact to="/dashboard" />
              {/*<Route exact path="/test-page" component={Test} />*/}

              <Route component={NotFound} />
            </Switch>
          </Layout>
        ) : (
          <PublicLayout>
            <Switch>
              <Route path="/verify-email" component={VerifyEmailByCode} />
              <Route exact path="/lts-secure" component={SecurePage} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/reset-password" component={ResetPassword} />
              <Route exact path="/create-account" component={CreateAccount} />
              <Route exact path="/terms" component={Terms} />
              <Route path="/register" component={Register} />
              <Route exact path="/trial-ended" component={Resubscribe} />
              <Route exact path="/subscription-ended" component={Resubscribe} />
              <Route path="/logout" component={Logout} />
              <Route path="/main-login" component={HSLogin} />
              <Route exact path="/" component={HSChooseLogin} />
              <Route path="/verify" component={VerifyEmail} />
              <Route
                path="/password-change-required"
                component={PasswordChangeRequired}
              />

              <Route
                exact
                path="/user-portfolio/:username"
                component={PreviewPublicPortfolio}
              />
              <Route component={NotFound} />
              {isHighSchoolStudent && (
                <Route path="/my-immersion" component={MyImmersion} />
              )}
            </Switch>
          </PublicLayout>
        )}
      </React.Fragment>
    </IntlProvider>
  )
}

const mapStateToProps = ({ lang }) => {
  const { locale } = lang
  return { locale }
}

export default connect(mapStateToProps, {})(Router)
