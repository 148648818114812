/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Router from './Router'
import jQuery from 'jquery'
import NewRouter from './newRouter'
window.jQuery = jQuery

function App({ basename }) {
  window.ATL_JQ_PAGE_PROPS = jQuery.extend(window.ATL_JQ_PAGE_PROPS, {
    triggerFunction: function (showCollectorDialog) {
      //Requires that jQuery is available!
      jQuery(document).ready(function () {
        jQuery('#myCustomTrigger').on('click', function (e) {
          e.preventDefault()
          showCollectorDialog()
        })
      })
    }
  })

  return (
    <React.Suspense fallback={''}>
      <BrowserRouter basename={basename}>
        <NewRouter />
      </BrowserRouter>
    </React.Suspense>
  )
}

export default App
