import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

const AccordionItemWrapper = (props) => {
  return (
    <div className="MY_MARKET_READY_GUIDE">
      <div
        className={` accordion accordion-border ${
          props.isOpened ? 'expanded' : ''
        }`}
      >
        <div
          className="accordion-header-box"
          onClick={() => props.handleAccordionClick()}
        >
          <div className={'accordion-header-title'}>{props.title}</div>
          <span
            className={`accordion-icon ${props.isOpened ? 'expanded' : ''}`}
          >
            {props.isExpanded ? (
              <FontAwesomeIcon
                icon={faAngleDown}
                className="me-2 me-md-0 arrow"
              />
            ) : (
              <FontAwesomeIcon
                icon={faAngleDown}
                className="me-2 me-md-0 arrow"
              />
            )}
          </span>
        </div>
        <div style={props.accordionStyle ? { ...props.accordionStyle } : {}}>
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default AccordionItemWrapper
