import React from 'react'
import FolderSidebarImage from '../../assets/images/HS-Sidebar-Icons/Dashboard (Full)-1200x.png'
import SidebarItem from './SidebarItem'
import ParentSidebarItem from './ParentSidebarItem'
import ParentDropdownItem from './ParentDropdownItem'
import DropdownItem from './DropdownItem'
import { useDispatch, useSelector } from 'react-redux'
import { setAccordionToggled } from '../../redux'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'

const Body = (props) => {
  const { isAdmin } = useSelector((state) => state.user.user)
  const dispatch = useDispatch()
  const location = useLocation()
  return (
    <ul
      className='list-unstyled components sidebar-menu-item'
      id='side-menu-main'
    >
      <SidebarItem
        onClick={() => {
          dispatch(setAccordionToggled(false))
          props.hideHeaderIcons()
        }}
        to={'/dashboard'}
        className={`${location.pathname.includes('dashboard') ? 'active' : ''}`}
        srcImage={FolderSidebarImage}
        title='MY Dashboard'
        isDropdown={false}
      />
      <ParentSidebarItem
        href='#whoAmI'
        aria-controls='whoAmI'
        srcImage={FolderSidebarImage}
        title='WHO AM I?'
        isDropdown={true}
      />
      <ParentDropdownItem id={'whoAmI'}>
        <DropdownItem title={'My Lts Journal'} to={'/lts-journal'} />
        <DropdownItem title={'My Mentorship Journal'} to={'/my-mentorship'} />
        <DropdownItem title={'My Wellnes Journal'} to={'/wellness-journal'} />
        <DropdownItem
          title={' My Personal Finance Journal'}
          to={'/student-personal-finance'}
        />
        <DropdownItem
          title={' My Leadership Journal'}
          to={'/my-leadership-journal'}
        />
      </ParentDropdownItem>
      <ParentSidebarItem
        ariaControls='whatCanIDo'
        href='#whatCanIDo'
        srcImage={FolderSidebarImage}
        title='WHAT CAN I DO?'
        isDropdown={true}
      />
      <ParentDropdownItem id={'whatCanIDo'}>
        <DropdownItem allowed={isAdmin} title={'Pathways'} to={'/pathways'} />
        <DropdownItem title={'Immersion'} to={'/my-immersion'} />
        <DropdownItem title={'Spotlight'} to='/spotlight' />
      </ParentDropdownItem>

      <ParentSidebarItem
        ariaControls='howDoIProveIt'
        href='#howDoIProveIt'
        srcImage={FolderSidebarImage}
        title='HOW DO I PROVE IT?'
        isDropdown={true}
      />
      <ParentDropdownItem id={'howDoIProveIt'}>
        <DropdownItem
          title={'MY MARKET-READY GUIDE'}
          to={'/My-Market-Ready-Guide'}
        />
        <DropdownItem title={'Certification System'} to={'/iamr'} />
      </ParentDropdownItem>
      <SidebarItem
        to={'/my-portfolio'}
        className={`${
          location.pathname.includes('my-portfolio') ? 'active' : ''
        }`}
        srcImage={FolderSidebarImage}
        title='MY PORTFOLIO'
        isDropdown={false}
      />
    </ul>
  )
}

export default Body
