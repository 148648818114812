import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactQuill from 'react-quill'
import { FormControl, FormGroup, InputGroup } from 'react-bootstrap'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMinus,
  faPencilAlt,
  faPlus,
  faSpinner
} from '@fortawesome/free-solid-svg-icons'
import '../../pages/JournalTables/styles.css'
import LtsButton from '../../components/LTSButtons/LTSButton'
import axiosInstance from '../../utils/AxiosInstance'
import { toast } from 'react-toastify'
export const JournalTableRows = ({
  rows,
  displayedCellIndex,
  tableIndex,
  loading,
  setLoading,
  inputRef,
  handleUpdateJournalTables,
  table,
  additionalStyling,
  editClicked,
  selectedCell,
  setSelectedCell,
  handlePencilClick,
  setDisplayedCellIndex,
  isClonedRow,
  parentRowIndex,
  isClonedTable
}) => {
  const [tableRows, setTableRows] = useState([])

  useEffect(() => {
    if (rows) setTableRows(rows)
  }, [rows])

  const onDeleteClonedRow = (row, index) => {
    axiosInstance
      .delete(`/ltsJournalTables/rowsClone/${row.id}`)
      .then((res) => {
        const newTableRows = [...tableRows]

        if (index !== undefined && index >= 0 && index < newTableRows.length) {
          newTableRows.splice(index, 1)
          toast.success('Row deleted successfully')
          setTableRows(newTableRows)
        }
      })
      .catch((err) => {
        toast.error('Error occurred during deleting row')
      })
  }

  const onAddRow = (row) => {
    const highestOrder = Math.max(
      ...tableRows.map((row) =>
        Math.max(
          ...(row.clonedRows.length > 0
            ? row.clonedRows.map((clonedRow) => clonedRow.order)
            : [0])
        )
      )
    )

    const modifiedRow = {
      order: highestOrder + 1,
      content: '',
      parentRowId: row.id,
      parentTableId: row.tableId,
      clonedTableId: row.clonedTableId ?? null,
      cells: row.cells.map((cell) => ({
        isEditable: cell.isEditable,
        colSpan: cell.colSpan,
        isTableHeader: cell.isTableHeader,
        isTableSubHeader: cell.isTableSubHeader,
        order: cell.order,
        inputTag: cell.inputTag,
        inputType: cell.inputType,
        content: cell.content,
        cellStyle: cell.cellStyle,
        userCells: null
      }))
    }

    axiosInstance
      .post('/ltsJournalTables/rowsClone', { row: modifiedRow })
      .then((res) => {
        const createdRow = res.data.row
        const newTableRows = [...tableRows]
        const foundRowIndex = newTableRows.findIndex(
          (table) => table.id === createdRow.parentRowId
        )
        newTableRows[foundRowIndex].clonedRows = [
          ...newTableRows[foundRowIndex].clonedRows,
          createdRow
        ]
        toast.success('Row added successfully')
        setTableRows(newTableRows)
      })
      .catch((err) => {
        toast.error('Error occurred during creating row')
      })
  }

  const parentRowIndexString = (rowIndex) => {
    if (parentRowIndex) {
      return `${parentRowIndex}-${rowIndex}`
    } else {
      return `${rowIndex}`
    }
  }

  return (
    <>
      {tableRows
        ?.toSorted((a, b) => a.order - b.order)
        ?.map((row, rowIndex) => {
          return (
            <React.Fragment key={row.id}>
              <JournalTableRow additionalStyle={{ position: 'relative' }}>
                {row?.cells
                  ?.toSorted((a, b) => a.order - b.order)
                  .map((cell, cellIndex) => {
                    return (
                      <React.Fragment key={cell.id}>
                        {!cell.isEditable ? (
                          <TableCellTitle
                            cell={cell}
                            key={cell.id}
                            additionalStyle={{
                              width: `${100 / table.gridColumns}%`,
                              minHeight: 70
                            }}
                            backgroundColor={'#fff'}
                          />
                        ) : displayedCellIndex &&
                          displayedCellIndex.tableIndex === tableIndex &&
                          displayedCellIndex.rowIndex ===
                            parentRowIndexString(rowIndex) &&
                          displayedCellIndex.cellIndex === cellIndex ? (
                          <React.Fragment>
                            <UserJournalTableCell
                              loading={loading}
                              setLoading={setLoading}
                              inputRef={inputRef}
                              cell={cell}
                              userCell={cell.userCells}
                              userCellValue={
                                cell.inputType === 'text'
                                  ? cell.userCells?.content
                                  : cell.userCells?.amount
                              }
                              handleChangeUserCell={(
                                cellToUpdate,
                                value,
                                isEdit
                              ) => {
                                let isCloned = isClonedTable
                                const args = [
                                  cellToUpdate,
                                  value,
                                  isEdit,
                                  table.id,
                                  row.id,
                                  cell.id,
                                  table.parentTableId,
                                  isClonedRow ? row.id : null
                                ]
                                return handleUpdateJournalTables(...args)
                              }}
                              key={cell.id}
                              additionalInputStyle={{
                                padding: '0px',
                                margin: '0px',
                                minHeight: 70
                              }}
                            />
                          </React.Fragment>
                        ) : (
                          <JournalTableCell
                            colSpan={cell.colSpan}
                            additionalStyling={{
                              display: 'flex',
                              gap: 6,
                              ...additionalStyling,
                              zIndex: 0
                            }}
                          >
                            <DisplayCellData
                              editClicked={editClicked}
                              selectedCell={selectedCell}
                              loading={loading}
                              userCellValue={
                                cell.inputType === 'text'
                                  ? cell.userCells?.content
                                  : cell.userCells?.amount
                              }
                              additionalStyle={{
                                minHeight: 70,
                                width: '100%',
                                zIndex: 9999
                              }}
                              setSelectedCell={setSelectedCell}
                              setDisplayedCellIndex={setDisplayedCellIndex}
                              openEditBox={(event) => {
                                if (!loading) {
                                  return handlePencilClick(
                                    tableIndex,
                                    parentRowIndexString(rowIndex),
                                    cellIndex,
                                    event
                                  )
                                }
                              }}
                            />
                          </JournalTableCell>
                        )}
                      </React.Fragment>
                    )
                  })}
                {row?.isCloneable ? (
                  <div className={'add_plus-sign'}>
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="plus-ico"
                      style={{
                        width: '22px',
                        height: '22px',
                        color: '#707070',
                        cursor: 'pointer'
                      }}
                      onClick={() => onAddRow(row)}
                    />
                  </div>
                ) : null}
                {isClonedRow ? (
                  <div className={'add_minus-sign'}>
                    <FontAwesomeIcon
                      icon={faMinus}
                      style={{
                        width: '16px',
                        height: '16px',
                        color: '#fff',
                        cursor: 'pointer'
                      }}
                      onClick={() => onDeleteClonedRow(row, rowIndex)}
                    />
                  </div>
                ) : null}
              </JournalTableRow>
              {/*//////////// CLONED ROWS //////////////*/}
              {row?.clonedRows?.length > 0 && (
                <JournalTableRows
                  rows={row?.clonedRows}
                  displayedCellIndex={displayedCellIndex}
                  tableIndex={tableIndex}
                  loading={loading}
                  setLoading={setLoading}
                  inputRef={inputRef}
                  handleUpdateJournalTables={(
                    obj,
                    value,
                    isEdit,
                    tableId,
                    rowId,
                    cellId
                  ) => {
                    const parentRowId = row.id
                    const clonedRowId = rowId
                    return handleUpdateJournalTables(
                      obj,
                      value,
                      isEdit,
                      tableId,
                      parentRowId,
                      cellId,
                      clonedRowId,
                      'fromClonedRows'
                    )
                  }}
                  table={table}
                  additionalStyling={additionalStyling}
                  editClicked={editClicked}
                  selectedCell={selectedCell}
                  setSelectedCell={setSelectedCell}
                  handlePencilClick={handlePencilClick}
                  setDisplayedCellIndex={setDisplayedCellIndex}
                  parentRowIndex={rowIndex}
                  isClonedRow={true}
                />
              )}
            </React.Fragment>
          )
        })}
    </>
  )
}

export const JournalTableRow = (props) => {
  const { children, additionalStyle } = props
  return (
    <tr className={'journal_table-row'} style={{ ...additionalStyle }}>
      {children}
    </tr>
  )
}
export const JournalTableCell = (props) => {
  const { isGray, colSpan, additionalStyling } = props

  return (
    <td
      colSpan={colSpan}
      style={{
        backgroundColor: isGray ? '#dfdfdf' : '#fff',
        ...additionalStyling,
        height: '100%',
        display: 'table-cell'
      }}
      className={'journal_table-data'}
      // className={'table_cell-title_box'}
    >
      {props.children}
    </td>
  )
}
const EditButton = (props) => {
  return (
    <span
      style={{
        backgroundColor: '#fff',
        height: '100%',
        padding: '8px 0',
        cursor: 'pointer'
      }}
      className={'d-flex justify-content-end align-items-center z-2 '}
      onClick={() => {
        props.openEditBox()
      }}
    >
      <FontAwesomeIcon className={'me-2'} icon={faPencilAlt} />
    </span>
  )
}
export const DisplayCellData = (props) => {
  const newRef = useRef(null)

  useEffect(() => {
    if (props.selectedCell) {
      function handleOutsideClick(e) {
        if (
          !e.target.classList.contains('edit-pencil') &&
          !e.target.classList.contains('edit-pencil-container') &&
          !e.target.classList.contains('journal_table-input') &&
          !props.loading
        ) {
          props.setDisplayedCellIndex(null)
          props.setSelectedCell(null)
        }
      }

      document.addEventListener('mousedown', handleOutsideClick)

      return () => {
        document.removeEventListener('mousedown', handleOutsideClick)
      }
    }
  }, [props.selectedCell])
  return (
    <span
      disabled={props.disabled}
      style={{
        ...props.additionalStyle,
        backgroundColor: '#fff',
        height: '100%',
        padding: '8px 0',
        cursor: 'pointer'
      }}
      className={'d-flex justify-content-between align-items-center '}
    >
      <div>
        <div>{props.userCellValue}</div>
        <div></div>
      </div>
      <div
        ref={newRef}
        onClick={(event) => {
          props.openEditBox(event)
        }}
        className={'edit-pencil-container d-flex justify-content-end'}
        style={{ padding: '16px 0 16px 16px' }}
      >
        <FontAwesomeIcon
          className={'z-3 ml-1 edit-pencil'}
          icon={faPencilAlt}
        />
      </div>
    </span>
  )
}

export const JournalTableCellInput = (props) => {
  const {
    cell,
    title,
    type,
    value,
    handleChange,
    width,
    inputName,
    isBold,
    isDisabled,
    additionalStyle,
    additionalInputStyle,
    inputTag,
    inputType,
    inputRef,
    setLoading
  } = props

  const handleTabKey = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault()
    }
  }
  const newStyle = {
    width: width ?? '100%',
    ...additionalInputStyle
  }

  const debounce = useCallback(
    _.debounce(async (func, value) => {
      func('debounce', value)
    }, 500),
    []
  )

  return (
    <div
      className={'journal_table-input__container'}
      style={{ ...additionalStyle, height: '100%' }}
    >
      {title ? (
        <div
          className={'journal_table-input__title'}
          style={{ fontWeight: isBold ? 600 : 400 }}
        >
          {title}
        </div>
      ) : null}
      <div className={` ${width ? '' : 'w-100'}`}>
        {inputTag === 'textarea' && (
          <textarea
            key={props.cell?.id}
            ref={inputRef}
            className={`journal_table-input py-2 px-2 text-dark `}
            disabled={isDisabled}
            // type={inputType}
            style={{ ...newStyle, resize: 'none' }}
            name={'textarea'}
            defaultValue={value}
            onKeyDown={handleTabKey}
            onChange={(e) => {
              debounce(() => handleChange(e.target.value))
              setLoading?.(true)
            }}
          />
        )}
        {inputTag === 'input' && (
          <input
            key={props.cell?.id}
            ref={inputRef}
            className={`journal_table-input py-2 px-2 text-dark `}
            disabled={isDisabled}
            type={inputType}
            style={newStyle}
            name={inputName ?? ''}
            defaultValue={value}
            onKeyDown={handleTabKey}
            onChange={(e) => {
              debounce(() => handleChange(e.target.value))
              setLoading?.(true)
            }}
          />
        )}
        {!inputTag && (
          <input
            key={props.cell?.id}
            ref={inputRef}
            className={`journal_table-input py-2 px-2 text-dark `}
            disabled={isDisabled}
            type={inputType}
            style={newStyle}
            name={inputName ?? ''}
            value={value}
            onKeyDown={handleTabKey}
            onChange={(e) => {
              debounce(() => handleChange(e.target.value))
              setLoading?.(true)
            }}
          />
        )}
      </div>
    </div>
  )
}

export const UserJournalTableCell = (props) => {
  return (
    <JournalTableCell
      colSpan={props.cell.colSpan}
      additionalStyling={{
        display: 'flex',
        gap: 6,
        ...props.additionalStyling,
        position: 'relative'
      }}
    >
      {props.userCell ? (
        <>
          <JournalTableCellInput
            cell={props.cell}
            additionalStyle={{
              width: '100%',
              height: '100%'
            }}
            additionalInputStyle={{ ...props.additionalInputStyle }}
            value={props.userCellValue}
            handleChange={(value) => {
              const isEdit = !!props.userCell
              return props.handleChangeUserCell(props.userCell, value, isEdit)
            }}
            isDisabled={props.isDisabled}
            inputType={props.cell.inputType}
            inputTag={props.cell.inputTag}
            inputRef={props.inputRef}
            setLoading={props.setLoading}
          />
          {props.loading && (
            <div
              className=""
              style={{
                color: '#01c5d1',
                position: 'absolute',
                zIndex: 999,
                bottom: 0,
                right: 13
              }}
            >
              <FontAwesomeIcon icon={faSpinner} className="" spin />
            </div>
          )}
        </>
      ) : (
        <JournalTableCellInput
          cell={props.cell}
          additionalStyle={{
            width: '100%'
          }}
          additionalInputStyle={{ ...props.additionalInputStyle }}
          handleChange={(value) => {
            const isEdit = !!props.userCell
            return props.handleChangeUserCell(props.cell, value, isEdit)
          }}
          isDisabled={props.isDisabled}
          inputType={props.cell.inputType}
          inputTag={props.cell.inputTag}
          inputRef={props.inputRef}
          setLoading={props.setLoading}
        />
      )}
    </JournalTableCell>
  )
}

export const TableCellTitle = ({ additionalStyle, backgroundColor, cell }) => {
  if (cell.isTableHeader) {
    return (
      <th
        style={{ ...additionalStyle, ...cell.cellStyle }}
        colSpan={cell.colSpan}
        className={'table_header-title_box'}
      >
        <div className={'table_header-title'} style={{ ...cell.cellStyle }}>
          {cell.content}
        </div>
      </th>
    )
  } else if (cell.isTableSubHeader) {
    return (
      <th
        style={{ ...additionalStyle, ...cell.cellStyle }}
        colSpan={cell.colSpan}
        className={'table-subheader-title_box'}
      >
        <div className={'table-subheader-title'} style={{ ...cell.cellStyle }}>
          {cell.content}
        </div>
      </th>
    )
  }
  return (
    <td
      style={{ ...additionalStyle, backgroundColor, ...cell.cellStyle }}
      colSpan={cell.colSpan}
      className={'table_cell-title_box'}
    >
      <div className={'table_cell-title'} style={{ ...cell.cellStyle }}>
        {cell.content}
      </div>
    </td>
  )
}
